import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import ViewUIPlus from 'view-ui-plus'
import 'view-ui-plus/dist/styles/viewuiplus.css'


import mavonEditor from 'mavon-editor'
import 'mavon-editor/dist/css/index.css'

createApp(App).use(store).use(router).use(ViewUIPlus).use(mavonEditor).mount('#app')
