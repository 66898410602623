export default {
    state: {
      title: '渲沅山庄',
      logo: require('@/assets/logo.png'),
      footerTitle: '自由软件 © 2023 ~ 至今',
  
      isCollapsed: false, // 导航栏[折叠: true, 展开: false]状态
    },
    mutations: {
      fold(state: Record<string, boolean>): void {
        state.isCollapsed = true;
      },
      unfold(state: Record<string, boolean>): void {
        state.isCollapsed = false;
      },
    },
  };
  