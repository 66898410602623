<template>
  <router-view />
</template>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.building {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  font-size: 24px;
  font-weight: 600;
  text-shadow: 4px 4px 0 #dddddd, 6px 6px 0 #eaeaea;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.flex-center-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.margin-0-16 {
  margin: 0 16px;
}

.margin-16-0 {
  margin: 16px 0;
}
</style>
