import { createStore } from 'vuex'

import Common from './common';
import Enums from './enums';
import Entities from './entities';
import User from './user';

export default createStore({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    common: Common,
    enums: Enums,
    entities: Entities,
    user: User,
  },
});
