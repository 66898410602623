import { getCurrentInstance } from "vue";
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomeView from "../views/HomeView.vue";
import Building from "@/views/building";
import { RouteLocationNormalized } from "vue-router";

const store = getCurrentInstance()?.proxy?.$store;

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    redirect: {
      name: "app",
    },
  },
  {
    path: "/app/outer",
    name: "app",
    component: () => import("../views/outer-frame/index"),
    children: [
      {
        path: "",
        name: "app-root",
        component: Building,
      },
      {
        path: "./",
        name: "app-root",
        component: Building,
      },
      {
        path: "online-tools",
        name: "online-tools",
        children: [
          // {
          //   path: "uuid",
          //   name: "online-tools-uuid",
          //   meta: {
          //     title: "渲沅山庄-UUID",
          //   },
          //   component: () => import("../views/online-tools/uuid"),
          // },
          {
            path: "md5",
            name: "online-tools-md5",
            meta: {
              title: "渲沅山庄-MD5",
            },
            component: () => import("../views/online-tools/md5"),
          },
          {
            path: "random",
            name: "online-tools-random",
            meta: {
              title: "渲沅山庄-随机字符串",
            },
            component: () => import("../views/online-tools/random-string"),
          },
          // {
          //   path: "city",
          //   name: "online-tools-city",
          //   meta: {
          //     title: "渲沅山庄-行政区划",
          //   },
          //   component: () =>
          //     import("../views/online-tools/administrative-division"),
          // },
        ],
      },
      {
        path: "404",
        name: "404",
        component: Building,
      },
    ],
  },
  // {
  //   path: '/app/uuus',
  //   name: 'uuus',
  //   component: () => import('../views/inner-frame/index.vue')
  // },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // },
  {
    path: "/:pathMatch(.*)",
    redirect: {
      name: "404",
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  document.title =
    to?.meta?.title || store?.state?.common?.title || "渲沅山庄-欢迎光临";
  next();
});

export default router;
